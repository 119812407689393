@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
#home {
    position: relative;
}
#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 1; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}

.ant-carousel .slick-dots li button {
    width: 16px !important;
    height: 16px !important;
    border-radius: 100% !important;
    background-color: #f08436 !important;
}

.ant-carousel .slick-dots li.slick-active button {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #f08436 !important;
}

/* .sliders {
    border: 1px solid red;
} */
